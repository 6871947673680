import "firebase/firestore";
import 'bootstrap/dist/css/bootstrap.min.css';
import './src/scss/index.scss';
import smoothscroll from 'smoothscroll-polyfill';

import React from "react"
import GlobalContextProvider from "./src/context/GlobalContextProvider"

smoothscroll.polyfill();
export const wrapRootElement = ({ element }) => {
  return <GlobalContextProvider> {element} </GlobalContextProvider>
}
